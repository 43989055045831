import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, BrowserRouter as Router, Route} from 'react-router-dom';
import {App} from "./app";
import {Layout} from "./views/Admin/Layout/Layout";
import StatisticMember from "./components/StatisticMember";
import {createStore, compose} from 'redux'
import {Provider} from "react-redux";
import SettingsEvents from "./views/Admin/Events/Registration/SettingsEvents";
import Form from "./views/Admin/Events/Registration/Form/Form";
import PaymentMethods from "./views/Admin/Events/Registration/PaymentMethods/PaymentMethods";
import SettingsPdfTicket from "./views/Admin/Events/Registration/PdfTicket/SettingsPdfTicket";
import Notifications from "./views/Admin/Events/Notifications/Notifications";
import RegistrationSettings from "./components/RegistrationSettings";
import PublishEvent from "./views/Admin/Events/PublishEvent/PublishEvent";
import MemberCharts from "./views/Admin/Courses/Course/Lessons/Statistic/Charts/MemberCharts"

const checkWindowFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

const defaultState = {
    orderReserve: null,
    bill: {
        "country_id": null,
        "inn": null,
        "registration_number": null,
        "unp": null,
        "kpp": null,
        "org_name": null,
        "address": null,
        "postal": null,
        "is_sbis": false,
    },
    formGift: {
        settings: {},
        data: {
            "buyer_name": null,
            "buyer_email": null,
            "buyer_text": null,
            "picture_id": null,
        }
    },
    payment: {},
    form: {data: {}},
    formTemplate: '',
    formStartTemplate: '',
    formPrevTemplate: '',
    formFloorId: '',
    formQuickReg: true,
    formBtnOtherTickets: false,
    formSelectedPlace: [],
    placeDifferent: [],
    formOrder: {},
    createOrder: null,
    idLesson: 0,
    modal: {
        open: false,
        title: '',
        content: '',
        handler: null,
        courseId: '',
        recaptcha: null,
        buttons: []
    },
    notice: {
        open: false,
        type: null,
        message: '',
        closingTime: null
    },
    notification: {
        open: false,
        type: null,
        title: '',
        message: ''
    },
    pagination: {
        "current-page": null,
        "from": null,
        "last-page": null,
        "per-page": null,
        "to": null,
        "total": null,
        "getLessonPaginator": function (val) {
            console.log(val)
        },
        "getUpdateLesson": null,
    },
    errorValidation: null,
    errorFields: null,
    errorSessions: null,
    percentProgress: 0,
    editorGroup: {
        name: "",
        color: "",
        type: "",
        id: ""
    },
    multiplaces: [],
    currentEvent: null,
    completeValid: null,
    approved: false,
    iframe: checkWindowFrame(),
    preview: null,
    paymentType: null,
    consult: null,
    notificationForm: null,
    includedSessions: null,
    schedules: {
        session_id: null,
        date: null,
        time: null,
        dates: null,
        times: null,
        sessions: null,
        tickets: null,
        is_preview: false,
        from: null
    },

    newEvent: {
        event_title: {value: '', error: null},
        category_id: {value: 2, error: null},
        is_widget: {value: 0, error: null},
        is_online: {value: 0, error: null},
        event_city_name: {value: '', error: null},
        event_city_id: {value: '', error: null},
        event_address: {value: '', error: null},
        event_url: {value: '', error: null},
        dateless: {value: 0, error: null},
        timezone: {value: 'Europe/Moscow', error: null},
        datetime_start: {value: null, error: null},
        datetime_end: {value: null, error: null},
        company_title: {value: '', error: null},
        company_email: {value: '', error: null},
        company_phone: {value: '', error: null},
        a5000: {value: 0, error: null},
        oneDay: {value: 0, error: null},
        ownerProps: null
    },
    ticketMember: {
        user: 0,
        place: []
    },
    //todo: проработать структуру приложения в панели управления, формы, лендинга и редактора по анологии ниже
    admin: {
        constants: {},
        event: {},
        events: [],
        profile: {},
    },
    widget: {order: null, bill: null, complete: null}
    //promo: {...}
    //editor: {...}
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_FORM_PAYMENT_TYPE":
            return {...state, paymentType: action.payload}
        case "SET_FORM_ORDER_RESERVE":
            return {...state, orderReserve: action.payload}
        case "SET_FORM_PREVIEW":
            return {...state, preview: action.payload}
        case "SET_FORM_BILL":
            return {...state, bill: action.payload}
        case "SET_FORM_CONSULT":
            return {...state, consult: action.payload}
        case "SET_FORM_APPROVED":
            return {...state, approved: action.payload}
        case "SET_FORM_PAYMENT":
            return {...state, payment: action.payload}
        case "SET_FORM_GIFT":
            return {...state, formGift: action.payload}
        case "SET_FORM_ORDER":
            return {...state, formOrder: action.payload}
        case "SET_FORM_CREATE_ORDER":
            return {...state, createOrder: action.payload}
        case "SET_FORM_SELECTED_PLACE":
            return {...state, formSelectedPlace: action.payload}
        case "SET_FORM_DIFFERENT_PLACE":
            return {...state, placeDifferent: action.payload}
        case "SET_FORM_TICKET_MEMBER":
            return {...state, ticketMember: action.payload}
        case "SET_FORM_FLOOR_ID":
            return {...state, formFloorId: action.payload}
        case "SET_FORM_QUICK_REG":
            return {...state, formQuickReg: action.payload}
        case "SET_FORM_BTN_OTHER_TICKETS":
            return {...state, formBtnOtherTickets: action.payload}
        case "SET_FORM_TEMPLATE":
            return {...state, formTemplate: action.payload}
        case "SET_FORM_START_TEMPLATE":
            return {...state, formStartTemplate: action.payload}
        case "SET_FORM_PREV_TEMPLATE":
            return {...state, formPrevTemplate: action.payload}
        case "SET_FORM_COMPLETE":
            return {...state, completeValid: action.payload}
        case "SET_FORM_NOTIFICATION":
            return {...state, notificationForm: action.payload}
        case "SET_FORM":
            return {...state, form: action.payload}
        case "SET_FORM_MULTIPLACES":
            return {...state, multiplaces: action.payload}
        case "SET_PAGINATION":
            return {...state, pagination: action.payload}
        case "SET_ERROR_VALIDATION":
            return {...state, errorValidation: action.payload}
        case "SET_ERROR_FIELDS":
            return {...state, errorFields: action.payload}
        case "SET_ERROR_SESSIONS":
            return {...state, errorSessions: action.payload}
        case "SET_ID_LESSON":
            return {...state, idLesson: action.payload}
        case "GET_ID_LESSON":
            return state
        case "SET_MODAL":
            return {...state, modal: action.payload}
        case "SET_NOTICE":
            return {...state, notice: action.payload}
        case "SET_NOTIFICATION":
            return {...state, notification: action.payload}
        case "SET_PERCENT_UPLOAD_FILE":
            return {...state, percentProgress: action.payload}
        case "SET_EDITOR_GROUP":
            return {...state, editorGroup: action.payload}
        case "SET_ERROR":
            return {...state, error: action.payload}
        case "SET_CONTENT_TYPE_TEXT":
            return {...state, currentEvent: action.payload}
        case "SET_SCHEDULE_CURRENT":
            return {...state, schedules: action.payload}

        case "SET_ADMIN":
            return {...state, admin: action.payload}
        case "SET_NEW_EVENT":
            return {...state, newEvent: action.payload}
        default:
            return state
    }
}
const enhancers = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const store = createStore(
    reducer,
    defaultState,
    enhancers
);

window.hostClient = document.getElementById('App')?.getAttribute('data-api-host');

const renderReactComponent = (id, component, path, data) => {
    const element = document.getElementById(id);
    if (element) {
        ReactDOM.render(
            <Provider store={store}>
                <BrowserRouter>
                    <Route path={path} component={component} data={data}/>
                </BrowserRouter>
            </Provider>,
            element
        );
    }
}

renderReactComponent('Layout', Layout, '*')

document.addEventListener("DOMContentLoaded", () => {
    document.getElementById('App') ? ReactDOM.render(
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>,
        document.getElementById("App")
    ) : null;

    /* Панель управления ->  Регистрация и билеты */
    renderReactComponent('react-section-tickets', SettingsEvents, '/admin/events/:id/form')
    renderReactComponent('react-section-settings-pdf', SettingsPdfTicket, '/admin/events/:id/form')
    renderReactComponent('react-section-settings-order', RegistrationSettings, '/admin/events/:id/form')
    renderReactComponent('react-section-fields', Form, '/admin/:type_event_id/:id/form')
    renderReactComponent('react-section-payment-methods', PaymentMethods, '/admin/:type_event_id/:id/form')

    /* Панель управления ->  Уведомления */
    renderReactComponent('react-section-notifications-tab', Notifications, '/admin/:type/:event_id/notifications')
    /* Настройка события -> кнопка публикации */
    renderReactComponent('react-button-publish', PublishEvent, '/admin/:type/:event_id/:tab')
    renderReactComponent('react-button-publish', PublishEvent, '/admin/:type/:event_id/')
    renderReactComponent('react-section-charts-tab', MemberCharts, '/admin/course/:eventId/statistic/charts')

    let collectionMember = document.getElementsByClassName("StatisticScheduleMember")
    if (collectionMember.length) {
        for (const member of collectionMember) {
            let orderTicket = member.getAttribute('data-order-ticket-id')
            const idElMember = `memberTicket-${orderTicket}`
            renderReactComponent(idElMember, () => StatisticMember(orderTicket), '/admin/statistic/:id/order/:id/')
        }
    }
});
