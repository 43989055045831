import React from 'react';
import IconZoomPlus from "../../../../../assets/images/icons/16/zoomP.svg";
import ReactTooltip from "react-tooltip";
import IconZoomMinus from "../../../../../assets/images/icons/16/zoomM.svg";
import ChevronLeft from "../../../../../assets/images/icons/16/chevron-left.svg";
import {useSelector} from "react-redux";

const ToolBarUser = ({handleScale, handleBackPage}) => {
    let selectorForm = useSelector(state => state)
    const availabilityBackBtn = () => {
        if (selectorForm.form.data.floors.length > 1) {
            return true
        }
        if (selectorForm.form.data.floors.length === 1 && selectorForm.schedules.sessions !== null) {
            return true
        }
        return false
    }

    return (
        <>
            <div className="UserBar">
                {availabilityBackBtn() && <button
                    className="a-btn a-btn_size_medium a-btn_type_secondary-ghost" onClick={() => handleBackPage()}>
                    <ChevronLeft/>
                </button>}
                <button data-tip="React-tooltip" data-for='toolTipZoomIn'
                        className="a-btn a-btn_size_medium a-btn_type_secondary-ghost" onClick={() => handleScale('+')}>
                    <IconZoomPlus/>
                </button>
                <ReactTooltip id="toolTipZoomIn" place="right" type="dark" effect="solid" className="reactTooltip">
                    <span>
                     Увеличить масштаб
                    </span>
                </ReactTooltip>
                <button data-tip="React-tooltip" data-for='toolTipZoomOut'
                        className="a-btn a-btn_size_medium a-btn_type_secondary-ghost" onClick={() => handleScale('-')}>
                    <IconZoomMinus/>
                </button>
                <ReactTooltip id="toolTipZoomOut" place="right" type="dark" effect="solid" className="reactTooltip">
                    <span>
                     Уменьшить масштаб
                    </span>
                </ReactTooltip>
            </div>
        </>
    );
};

export default ToolBarUser;
