import {apiAdmin, apiAdminOld, apiCabinet, apiForm, apiPartners, apiUpload, apiPrefix, apiPartner} from './PREFIX.js'

export const API = {
    // выгрузка участников
    exportCrmEvent: {
        method: 'POST',
        path: (id) => `${apiAdmin}/apps/eventicious/export/${id}`,
    },
    //удаление события из срм аккаунта
    deleteCrmEvent: {
        method: 'DELETE',
        path: (id, owner, crm_type) => `${apiAdmin}/v1/crm-events/${id}?owner=${owner}${crm_type}`,
    },
    // Удаление группы для crm аккаунта:
    deleteCrmGroup: {
        method: 'DELETE',
        path: (crmAccount, groupId) => `${apiAdmin}/crm-accounts/${crmAccount}/groups/${groupId}`,
    },
    // Создание группы для crm аккаунта:
    createCrmGroup: {
        method: 'POST',
        path: (crmAccount) => `${apiAdmin}/crm-accounts/${crmAccount}/groups`,
    },
    // Получение групп для crm аккаунта:
    getCrmAccountsGroups: {
        method: 'GET',
        path: (id) => `${apiAdmin}/crm-accounts/${id}/groups`,
    },
    // Получение билетов для модалки связывания экспорта:
    getEventSettings: {
        method: 'GET',
        path: (id, owner, crm_type) => `${apiAdmin}/v1/crm-events/${id}?include=tickets&owner=${owner}${crm_type}`,
    },
    // изменение события crm аккаунта
    patchCrmEvent: {
        method: 'PATCH',
        path: (id, owner, crm_type) => `${apiAdmin}/v1/crm-events/${id}?owner=${owner}${crm_type}`,
    },
    // Удаление CRM аккаунтов приложения
    removeCrmAccounts: {
        method: 'DELETE',
        path: (id, owner, crm_type) => `${apiAdmin}/v1/crm-accounts/${id}?owner=${owner}${crm_type}`,
    },
    // Получение событий срм аккаунтов
    getCrmEvents: {
        method: 'GET',
        path: (q, crm_type) => `${apiAdmin}/v1/crm-events${q}${crm_type}`,
    },
    // Получение аккаунтов приложения
    getCrmAccounts: {
        method: 'GET',
        path: (q, crm_type) => `${apiAdmin}/v1/crm-accounts${q}${crm_type}`,
    },
    // Подключение события к аккаунта приложения
    postCrmEvents: {
        method: 'POST',
        path: (owner, crm_type) => `${apiAdmin}/v1/crm-events?owner=${owner}${crm_type}`,
    },
    // Подключение аккаунта приложения
    postCrmAccounts: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/crm-accounts/`,
    },
    getQrCodeTicket: {
        method: 'GET',
        path: (event_id) => `${apiAdmin}/events/${event_id}/tickets_qrcode`,
    },
    // Получить текущий шаблон билета.
    getPdfTemplateTicket: {
        method: 'GET',
        path: (event_id) => `${apiAdmin}/events/${event_id}/tickets_pdf_template`,
    },
    //изменение содержимого qr-кода
    postQrCodeTicket: {
        method: 'POST',
        path: (event_id) => `${apiAdmin}/events/${event_id}/tickets_qrcode`,
    },
    //Загрузка html шаблона билета
    uploadTemplateTicket: {
        method: 'POST',
        path: (event_id) => `${apiAdmin}/events/${event_id}/tickets_pdf_template`,
    },
    //Загрузка картинки в билет
    uploadImageTicket: {
        method: 'POST',
        path: (event_id) => `/api/upload/admin/events/${event_id}/ticket-banner`,
    },
    //Загрузка логотипа в билет
    uploadLogoTicket: {
        method: 'POST',
        path: (event_id) => `/api/upload/admin/events/${event_id}/logo`,
    },
    //Удаление шаблона билета
    deleteTemplateTicket: {
        method: 'DELETE',
        path: (event_id) => `/api/admin/events/${event_id}/tickets_pdf_template`,
    },
    //Удаление картинки из билета
    deleteImageTicket: {
        method: 'DELETE',
        path: (event_id) => `/api/upload/admin/events/${event_id}/ticket-banner`,
    },
    //Удаление логотипа из билета
    deleteLogoTicket: {
        method: 'DELETE',
        path: (event_id) => `/api/upload/admin/events/${event_id}/logo`,
    },
    payoutApprove: {
        method: 'POST',
        path: (id) => `${apiPartner}/v1/payouts/approve/${id}`,
    },
    deleteUserPartners: {
        method: 'DELETE',
        path: (id) => `${apiAdmin}/v1/partners/${id}`,
    },
    patchUserPartners: {
        method: 'PATCH',
        path: (id) => `${apiAdmin}/v1/partners/${id}`,
    },
    createUserPartners: {
        method: 'POST',
        path: (id) => `${apiAdmin}/v1/partners`,
    },
    getUserPartners: {
        method: 'GET',
        path: (id, isEvents, link) => `${apiAdmin}/v1/${isEvents ? 'events' : 'courses'}/${id}/partners${link}`,
    },
    updateEventPartner: {
        method: 'PATCH',
        path: (id) => `${apiAdmin}/v1/event-partners/${id}`,
    },
    createPartner: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/event-partners`,
    },
    getEventPartner: {
        method: 'GET',
        path: (id, isEvent) => `${apiAdmin}/v1/${isEvent ? 'events' : 'courses'}/${id}/event-partner`,
    },
    getPartnerPromocode: {
        method: 'GET',
        path: () => `${apiPartners}/user`
    },
    setPartnerPromocode: {
        method: 'POST',
        path: () => `${apiPartners}/code`
    },
    cashoutPartner: {
        method: 'POST',
        path: () => `${apiPartners}/cashout`
    },
    getPartnerRegistration: {
        method: 'GET',
        path: (query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => params.push([key, value].join('=')))
            result = `?${params.join('&')}`

            return `${apiPartners}/registration${result}`
        }
    },
    getPartnerEvents: {
        method: 'GET',
        path: (query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => {
                key === 'status' ? params.push([`filter[partner_status]`, value].join('=')) : params.push([`page[${key}]`, value].join('='))
            })
            result = `?${params.join('&')}`

            return `${apiPartner}/v1/events${result}`
        }
    },
    getPayouts: {
        method: 'GET',
        path: () => `${apiPartner}/v1/payouts`
    },
    getFormCode: {
        method: 'GET',
        path: (id, isEvent) => `${apiPartner}/v1/partners/${id}/form-codes`
    },
    getFormEvent: {
        method: 'GET',
        path: (id) => `${apiForm}/event/${id}`
    },
    getPartnerEvent: {
        method: 'GET',
        path: (id) => `${apiPartner}/v1/events/${id}`
    },
    getPartnerProfit: {
        method: 'GET',
        path: (query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => params.push([key, value].join('=')))
            result = `?${params.join('&')}`

            return `${apiPartners}/profit${result}`
        }
    },
    getPartnerChart: {
        method: 'GET',
        path: (partner_id, query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => params.push([key, value].join('=')))
            result = `?${params.join('&')}`

            return `${apiPartner}/v1/partners/${partner_id}/statistics${result}`
        }
    },
    getPartnerEventProfit: {
        method: 'GET',
        path: (partner_id, query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => params.push([key, value].join('=')))
            result = `?${params.join('&')}`

            return `${apiPartner}/v1/partners/${partner_id}/orderPartners${result}`
        }
    },
    favoritePartner: {
        method: (method) => method,
        path: (id) => `${apiPartner}/v1/events/${id}/favorite`
    },
    approvePartner: {
        method: 'POST',
        path: () => `${apiPartner}/v1/partners/`
    },
    updatePartner: {
        method: 'PATCH',
        path: (id) => `${apiPartner}/v1/partners/${id}`
    },
    rejectPartner: {
        method: 'DELETE',
        path: (partner_id) => `${apiPartner}/v1/partners/${partner_id}`
    },
    //Создание события
    createEvent: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/events/`,
    },
    //Отправка шагов создания события
    postStep: {
        method: 'POST',
        path: (isEvent) => `${apiAdmin}/v1/${isEvent ? 'events' : 'courses'}/check/`,
    },
    // список API токенов
    getApiToken: {
        method: 'GET',
        path: (page, size) => `${apiAdmin}/v1/user-api-tokens?page[number]=${page}&page[size]=${size}`,
    },
    // создание API токена
    createApiToken: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/user-api-tokens`,
    },
    // удаление API токена
    deleteApiToken: {
        method: 'DELETE',
        path: (id) => `${apiAdmin}/v1/user-api-tokens/${id}`,
    },
    //Получение значений для создания курса
    getCourseConstants: {
        method: 'GET',
        path: `${apiAdmin}/courses/constants/`,
    },
    //Получение значений для создания курса
    getEventsConstants: {
        method: 'GET',
        path: `${apiAdmin}/events/constants/`,
    },
    getDomainEmails: {
        method: 'GET',
        path: (event_id, type) => `${apiAdmin}/v1/${type}/${event_id}/domain-emails/`
    },
    getAdditionalEmails: {
        method: 'GET',
        path: (event_id, type) => `${apiAdmin}/v1/${type}/${event_id}/additional-emails/`
    },
    createAdditionalEmails: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/additional-emails/`
    },
    updateSubscribeOwnerNotification: {
        method: 'PATCH',
        path: (additional_email_id) => `${apiAdmin}/v1/additional-email-events/${additional_email_id}`
    },
    subscribeOwnerNotification: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/additional-email-events/`
    },
    createOwnerNotification: {
        method: 'POST',
        path: (additional_email_id, query) => {
            let params = [], result

            Object.entries(query).map(([key, value]) => {
                params.push([`filter[${key}]`, value].join('='))
            })
            result = additional_email_id ? [additional_email_id, `?${params.join('&')}`].join('/') : `?${params.join('&')}`

            return `${apiAdmin}/v1/additional-emails/${result}`
        }
    },
    getOwnersNotification: {
        method: 'GET',
        path: (event_id, type) => `${apiAdmin}/v1/${type}/${event_id}/additional-email-events/`
    },
    getNotificationList: {
        method: 'GET',
        path: (event_id) => `${apiAdmin}/events/${event_id}/notifications`
    },
    getNotificationItem: {
        method: 'GET',
        path: (notification_id) => `${apiAdmin}/v1/notifications/${notification_id}/`
    },
    updateNotificationItem: {
        method: 'PATCH',
        path: (notification_id) => `${apiAdmin}/v1/notifications/${notification_id}/`
    },
    createNotificationItem: {
        method: 'POST',
        path: `${apiAdmin}/v1/notifications/`
    },
    getNotificationTemplate: {
        method: 'GET',
        path: (template_id) => `${apiAdmin}/v1/notification-templates/${template_id}`
    },
    getNotificationTemplates: {
        method: 'GET',
        path: `${apiAdmin}/v1/notification-templates/`
    },
    createNotificationTemplate: {
        method: 'POST',
        path: (event_id) => `${apiAdmin}/v1/notification-templates?event_id=${event_id}`
    },
    planResendNotification: {
        method: 'POST',
        path: (notification_id) => `${apiAdmin}/v1/notifications/${notification_id}/resend/`
    },
    testNotificationItem: {
        method: 'POST',
        path: (template_id) => `${apiAdmin}/v1/notifications/${template_id}/test/`
    },
    // отозвать сертификат
    revokeCertificates: {
        method: 'PATCH',
        path: () => `${apiAdmin}/v1/certificates/revoke`,
    },
    // выдать сертификат
    reGiveCertificates: {
        method: 'POST',
        path: (id) => `${apiAdmin}/v1/certificates/${id}/resend`,
    },
    // выдать сертификат
    giveCertificates: {
        method: 'PATCH',
        path: () => `${apiAdmin}/v1/certificates/give`,
    },
    //смена способов оплаты
    changePaymentMethod: {
        method: 'PATCH',
        path: (is_course, event_id, params) => `${apiAdmin}/${is_course ? 'courses' : 'events'}/${event_id}/settings/payment-types${params ? `?${params}` : ''}`
    },
    //получение сессий
    getStatisticSessions: {
        method: 'GET',
        path: (event_id, params) => {
            let serialized = `${Object.entries(params).map(x => x[1] && `${x[0]}=${encodeURIComponent(x[1])}`).filter(x => x ?? x).join('&')}`
            let url = `${apiAdmin}/events/${event_id}/statistics/sessions`
            if (serialized) url = `${url}?${serialized}`
            return url
        }
    },
    // получение списка отправленных документов
    getSentList: {
        method: 'GET',
        path: (params) => params ? `${apiAdmin}/send-docs?${params}` : `${apiAdmin}/send-docs`,
    },
    // получение пакетов документов и их параметров
    getDocuments: {
        method: 'GET',
        path: () => `${apiAdmin}/services/send-docs`,
    },
    // покупка пакета документов
    buyDocuments: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/services/send-docs/buy/${id}/`,
    },
    // покупка пакета документов при недостаточном балансе
    buyDocumentsOnline: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/services/send-docs/pay-online/${id}`,
    },
    // вход в Мой Налог
    connectNalog: {
        method: 'POST',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/moy_nalog/auth/login`,
    },
    // выход из Мой Налог
    disconnectNalog: {
        method: 'DELETE',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/moy_nalog/`,
    },
    // Отправить смс на номер
    smsNalog: {
        method: 'POST',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/moy_nalog/auth/sms`,
    },
    // авторизация по коду подтверждения
    confirmNalog: {
        method: 'POST',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/moy_nalog/auth/code`,
    },
    // получение списка реквизитов
    getRequisitesList: {
        method: 'GET',
        path: () => `${apiAdmin}/requisites`,
    },
    // создание реквизита
    createRequisite: {
        method: 'POST',
        path: () => `${apiAdmin}/requisites`,
    },
    // сделать реквизит основным
    setMainRequisite: {
        method: 'POST',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/set_main`,
    },
    // обновить данные реквизита
    patchRequisite: {
        method: 'PATCH',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/`,
    },
    deleteRequisite: {
        method: 'DELETE',
        path: (id, type) => `${apiAdmin}/requisites/${id}/${type}/`,
    },
    // обновление регистрационных данных в заказанном билете
    removePromo: {
        method: 'DELETE',
        path: (orderId) => `${apiForm}/order/${orderId}/promocode`,
    },
    putPromoValue: {
        method: 'PUT',
        path: (orderId, value) => `${apiForm}/order/${orderId}/promocode?promocode=${value}`,
    },
    // обновление регистрационных данных в заказанном билете
    patchRegOrder: {
        method: 'PATCH',
        path: (orderId, orderTicketId) => `${apiForm}/order/${orderId}/ticket/${orderTicketId}/registration`,
    },
    // обновление опций в билете
    patchOrder: {
        method: 'PATCH',
        path: (orderId) => `${apiForm}/order/${orderId}/ticket`,
    },
    // создание заказа
    createOrder: {
        method: 'POST',
        path: (eventId) => `${apiForm}/event/${eventId}`,
        baseURL: ''
    },
    //создание консультации
    createConsult: {
        method: 'POST',
        path: (eventId) => `${apiForm}/event/${eventId}/consult`,
    },
    // свободный запрос
    getUrl: {
        method: 'GET',
        path: (str) => `${str}`, //TODO research
    },

    // получение информации о месте
    getPlaceInfo: {
        method: 'GET',
        path: (eventId, floorId, param, placeId) => `${apiForm}/event/${eventId}/floor/${floorId}?${param}&floor_place_id=${placeId}`,
    },
    // получение сессии расписания
    getFloorForm: {
        method: 'GET',
        path: (eventId, floorId, param) => `${apiForm}/event/${eventId}/floor/${floorId}?${param}`,
    },
    // получение сессии расписания
    getFormScheduleUrl: {
        method: 'GET',
        path: (formId, param) => `${apiForm}/event/${formId}/?${param}`,
    },
    // форма регистрации расписание/залы/билеты
    getForm: {
        method: 'GET',
        path: (object) => {
            let result
            object.ticket_id = object.ticket_id.filter(x => x ?? x)
            if (object.ticket_id.length) {
                result = `${apiForm}/event/${object.id}/?ticket_id=${object.ticket_id}`
            } else {
                result = `${apiForm}/event/${object.id}`
            }
            return result
        },
    },
    // форма регистрации расписание/залы/билеты (без учета статусов)
    getFormPreview: {
        method: 'GET',
        path: (object) => {
            let result
            object.ticket_id = object.ticket_id.filter(x => x ?? x)
            if (object.ticket_id.length) {
                return `${apiForm}/event/${object.id}/preview/?ticket_id=${object.ticket_id}`
            } else {
                return `${apiForm}/event/${object.id}/preview`
            }
            return result
        },
    },
    // удалить зал
    setDeleteFloor: {
        method: 'DELETE',
        path: (id) => `${apiAdmin}/floors/${id}`,
    },
    // список залов
    getFloorList: {
        method: 'GET',
        path: (query) => {
            let params = [], result
            Object.entries(query).map(([key, value]) => params.push([key, value].join('=')))
            result = params.length ? [`?${params.join('&')}`].join('/') : ''

            return `${apiAdmin}/floors/${result}`
        }
    },
    // получения зала привязанного к событию
    getAttachedFloors: {
        method: 'GET',
        path: (id) => {
            return `${apiAdmin}/floors/attached/${id}?per_page=100`
        },
    },
    // Подключение зала к событию:
    attachFloors: {
        method: 'POST',
        path: (floorId, eventId) => `${apiAdmin}/floors/${floorId}/${eventId}/attach`,
    },
    // Отключение зала от события:
    detachFloors: {
        method: 'POST',
        path: (floorId, eventId) => `${apiAdmin}/floors/${floorId}/${eventId}/detach`,
    },
    // получение схемы зала
    getFloor: {
        method: 'GET',
        path: (id) => `${apiAdmin}/floors/${id}/`,
    },
    // получение схемы зала по расписанию
    getFloorStatus: {
        method: 'GET',
        path: (floorId, eventId, session_id, date, time) => `${apiAdmin}/floors/${floorId}/${eventId}/status?${session_id ? `session_id=${session_id}` : ''}${date ? `&date=${date}` : ''}${time ? `&time=${time}` : ''}`,
    },
    // сохранение статусов
    setFloorStatus: {
        method: 'POST',
        path: (floorId, eventId) => `${apiAdmin}/floors/${floorId}/${eventId}/status`,
    },
    // получение схемы зала по расписанию c ссылкой
    getFloorStatusUrl: {
        method: 'GET',
        path: (floorId, eventId, url) => `${apiAdmin}/floors/${floorId}/${eventId}/status?${url}`,
    },
    // получение первоночальной схемы зала
    getFloorStatusDefault: {
        method: 'GET',
        path: (floorId, eventId) => `${apiAdmin}/floors/${floorId}/${eventId}/status`,
    },
    // изменение схемы зала
    setNewFloor: {
        method: 'POST',
        path: (id) => `${apiAdmin}/floors/`,
    },
    // изменение схемы зала
    setEditFloor: {
        method: 'PUT',
        path: (id) => `${apiAdmin}/floors/${id}/`,
    },
    //Получение расписания
    getSchedule: {
        method: 'GET',
        path: (id) => `${apiAdminOld}/events/${id}/schedule/api`,
    },
    //создание билета
    saveNewTicket: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/ticket/add/`
    },
    //редактирование билета
    saveEditedTicket: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/ticket/edit/`
    },
    //удаление билета
    deleteTicket: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/ticket/delete/`,
    },
    //удаление опции билета
    deleteOptionTicket: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/option/delete/`,
    },
    //сортировка билета
    shuffleTicket: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/tickets/shuffle/`,
    },
    //Удаление расписания
    deleteSchedule: {
        method: 'DELETE',
        path: (id, scheduleId) => `${apiAdminOld}/events/${id}/schedule/api/${scheduleId}`,
    },
    //Создание расписания
    setNewSchedule: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/events/${id}/schedule/api`,
    },
    //Сохранение расписания
    setSchedule: {
        method: 'PATCH',
        path: (id, scheduleId) => `${apiAdminOld}/events/${id}/schedule/api/${scheduleId}`,
    },
    getOwnerProps: {
        method: 'GET',
        path: `${apiAdmin}/v1/owner-properties/`,
    },
    //Получение пользовательских данных
    getEventBases: {
        method: 'GET',
        path: (owner, q, crm_type) => `${apiAdmin}/v1/event-bases?owner=${owner}${q ? `&q=${q}` : ''}${crm_type}`,
    },
    //Получение пользовательских данных
    getUserData: {
        method: 'GET',
        path: `${apiAdmin}/v1/users/me/`,
    },
    //Начальное создание курса
    createCourse: {
        method: 'POST',
        path: () => `${apiAdmin}/v1/courses/`,
    },
    //Получение значений для создания курса
    getCourseCreationParams: {
        method: 'GET',
        path: `${apiAdmin}/courses/constants/`,
    },
    //Получить курс
    getCourse: {
        method: 'GET',
        path: (id) => `${apiAdmin}/v1/courses/${id}/`,
    },
    //Получить события
    getEvents: {
        method: 'GET',
        path: (id) => `${apiAdmin}/v1/events/${id}/`,
    },
    // Получение каптчи для обратной свзяи из списка курсов
    getFeedBackCaptcha: {
        method: 'GET',
        path: (id) => `${apiCabinet}/v1/feedback/${id}/`,
    },
    postFeedBackCourse: {
        method: 'POST',
        path: (id) => `${apiCabinet}/v1/feedback/${id}/`,
    },
    feedbackRequisite: {
        method: 'POST',
        path: (id) => `/api/internal/feedback/form/${id}`,
    },
    //Проверка рекапчи курс
    getPublishCourse: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/publish/${id}/`,
    },
    //получить рекаптчу публикация события
    getPublishEvent: {
        method: 'GET',
        path: (id) => `${apiAdmin}/v1/events/${id}/publish/`,
    },
    postPublishEvent: {
        method: 'POST',
        path: (id) => `${apiAdmin}/v1/events/${id}/publish/`,
    },
    //Отправка рекапчи
    postPublishCourse: {
        method: 'POST',
        path: (id) => `${apiAdmin}/v1/courses/${id}/publish/`,
    },
    //Отменить курс
    cancelCourse: {
        method: 'POST',
        path: (id) => `${apiAdmin}/v1/courses/${id}/cancel/`,
    },
    //Добавить в архив курс
    archiveCourse: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/archive/${id}/`,
    },
    //Скопировать курс
    copyCourse: {
        method: 'POST',
        // path:
    },
    //Получить список курсов c учетом фильтров
    getCoursesList: {
        method: 'GET',
        path: (page, size, title, value, include) => {
            let params

            params = []
            if (page) {
                params.push(['page[number]', page].join('='))
            }
            if (size) {
                params.push(['page[size]', size].join('='))
            }
            if (value) {
                params.push(['filter[state]', value].join('='))
            }
            if (title) {
                params.push(['filter[title]', title].join('='))
            }
            if (include) {
                params.push(['include', include.join(',')].join('='))
            }

            return `${apiAdmin}/v1/courses${params.length ? `?${params.join('&')}` : ''}`
        }
    },
    addToFavorite: {
        method: 'POST',
        path: (id) => `${apiAdmin}/events/${id}/favorite`,
    },
    removeFromFavorite: {
        method: 'DELETE',
        path: (id) => `${apiAdmin}/events/${id}/favorite`,
    },
    // выгрузка учеников
    importStudent: {
        method: 'POST',
        path: (id) => `${apiAdmin}/courses/${id}/export-members`,
    },
    //Получить список уроков в курсе с пагинацией
    getPaginationLessonsList: {
        method: 'GET',
        path: (id, page, size, include) => {
            let params

            params = []
            if (page) {
                params.push(['page[number]', page].join('='))
            }
            if (size) {
                params.push(['page[size]', size].join('='))
            }
            if (include) {
                params.push(['include', include.join(',')].join('='))
            }

            return `${apiAdmin}/v1/courses/${id}/lessons${params.length ? `?${params.join('&')}` : ''}`
        },
    },
    //Получить список уроков в курсе
    getLessonsList: {
        method: 'GET',
        path: (id) => `${apiAdmin}/v1/courses/${id}/lessons/`,
    },
    //Получить список уроков в курсе
    getEventsList: {
        method: 'GET',
        path: (id) => `${apiAdmin}/v1/events/${id}/lessons/`,
    },
    //Получить отдельный урок
    getLesson: {
        method: 'GET',
        path: (lesson_id, include) => {
            let params

            params = []
            if (include) {
                params.push(['include', include.join(',')].join('='))
            }

            return `${apiAdmin}/v1/lessons/${lesson_id}${params.length ? `?${params.join('&')}` : ''}`
        },
    },
    //Удалить урок из списка уроков
    deleteLesson: {
        method: 'DELETE',
        path: (lessonId) => `${apiAdmin}/v1/lessons/${lessonId}/`,
    },
    //Создать урок
    createLesson: {
        method: 'POST',
        path: `${apiAdmin}/v1/lessons/`,
    },
    //Обновить урок
    patchLesson: {
        method: 'PATCH',
        path: (lessonId) => `${apiAdmin}/v1/lessons/${lessonId}/`,
    },

    //Изменение позиции блоков
    changeLessonPosition: {
        method: 'POST',
        path: `${apiAdmin}/v1/lessons/shuffle/`,
    },

    //Получить список заданий к уроку
    getTasksForLesson: {
        method: 'GET',
        path: (id, lessonId) => `${apiAdmin}/v1/lessons/${lessonId}/`,
    },
    // Изменения по шапке курса
    changeCourseInfo: {
        method: 'PATCH',
        path: (id, is_course) => `${apiAdmin}/v1/${is_course ? 'courses' : 'events'}/${id}/`,
    },

    //--//--//
    //Создание блоков для урока
    createBlock: {
        method: 'POST',
        path: (type) => `${apiAdmin}/v1/${type}/`,
    },
    //Обновление блоков для урока
    patchBlock: {
        method: 'PATCH',
        path: (type, id) => `${apiAdmin}/v1/${type}/${id}/`,
    },
    //Удаление блоков для урока
    deleteBlock: {
        method: 'DELETE',
        path: (id) => `${apiAdmin}/v1/lesson-blocks/${id}/`,
    },
    //Изменение позиции блоков
    changeBlockPosition: {
        method: 'POST',
        path: `${apiAdmin}/v1/lessons/shuffle-blocks/`,
    },
    getMadtestPayload: {
        method: 'GET',
        path: (block_id) => `${apiCabinet}/v1/test-blocks/${block_id}/payload`
    },
    //Список эмэйлов для оферты
    getEmails: {
        method: 'GET',
        path: (id, type) => `${apiAdmin}/v1/${type ? type : 'courses'}/${id}/emails/`,
    },
    //Получить оферту
    getOferta: {
        method: 'GET',
        path: (id, type) => `${apiAdminOld}/settings/${id}/oferta/`,
    },
    //Сохранить оферту
    saveOferta: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/oferta/do`,
    },
    getSettingsToggles: {
        method: 'GET',
        path: (id) => ``,
    },
    setSettingsToggle: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/events/${id}/form/set/`,
    },
    //Получить статистику для курса
    getStatistics: {
        method: 'GET',
        path: (id, number, q = '', ticket_ids = '') =>
            `${apiAdmin}/v1/courses/${id}/statistic/?page[number]=${number}&q=${q}&ticket_ids=${ticket_ids}`,
    },
    //Получить статусы для статистики
    getStatisticsConst: {
        method: 'GET',
        path: `${apiAdmin}/courses/order_statuses/get/`,
    },
    //Получить типы билетов курса
    getCourseTypes: {
        method: 'GET',
        path: (id) => `${apiAdmin}/courses/${id}/ticket_types/get`,
    },
    //Получить билеты для курса
    getCourseTickets: {
        method: 'GET',
        path: (id, type) => `${apiAdmin}/v1/${type ? type : 'courses'}/${id}/tickets/?page[number]=1&page[size]=1000`,
    },
    //скрыть билет
    patchActiveTicket: {
        method: 'PATCH',
        path: (event_id, ticket_id) => `${apiAdmin}/v1/events/${event_id}/tickets/${ticket_id}/set_activity`,
    },
    //Опубликовать билет
    postCourseTicket: {
        method: 'POST',
        path: `${apiAdmin}/v1/tickets/`,
    },
    copyTicket: {
        method: 'POST',
        path: (event_id) => `${apiAdminOld}/settings/${event_id}/form/ticket/copy/`
    },

    //Удалить билет
    deleteCourseTicket: {
        method: 'DELETE',
        path: (ticketId) => `${apiAdmin}/v1/tickets/${ticketId}/`,
    },
    //Обновить билет
    patchCourseTicket: {
        method: 'PATCH',
        path: (ticketId) => `${apiAdmin}/v1/tickets/${ticketId}/`,
    },
    //Изменить порядок полей в билете
    changeTicketPosition: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/tickets/shuffle/`,
    },

    /* Получение полей для рег. формы в ПУ */
    getFieldsForm: {
        method: (method) => method,
        path: (is_course, event_id, params) => `${apiAdmin}/v1/${is_course ? 'courses' : 'events'}/${event_id}/registration-forms/${params ? `?${params}` : ''}`
    },

    /* Управление полями рег. формы в ПУ */
    controlFieldsForm: {
        method: (method) => method,
        path: (record) => `${apiAdmin}/v1/registration-forms/${record ? record : ''}`,
    },

    //Опубликовать форму
    postCourseForm: {
        method: 'POST',
        path: `${apiAdmin}/v1/registration-forms/`,
    },
    //Обновить форму
    patchCourseForm: {
        method: 'PATCH',
        path: (formId) => `${apiAdmin}/v1/registration-forms/${formId}/`,
    },
    //Удалить форму
    deleteCourseForm: {
        method: 'DELETE',
        path: (formId) => `${apiAdmin}/v1/registration-forms/${formId}/`,
    },
    //Изменить порядок полей в форме
    changeFieldPosition: {
        method: 'POST',
        path: (id) => `${apiAdminOld}/settings/${id}/form/registration/shuffle/`,
    },
    //Получить цвета кнопок
    getCourseColors: {
        method: 'GET',
        path: (id, type) => `${apiAdmin}/v1/${type ? type : 'courses'}/${id}/buttons/`,
    },
    //Опубликовать цвета кнопок
    postCourseColors: {
        method: 'POST',
        path: (courseId, type) => `${apiAdminOld}/events/${courseId}/form/buttons/`,
    },

    postPhonenumber: {
        method: 'POST',
        path: `${apiAdminOld}/sms/generate/`,
    },
    postCode: {
        method: 'POST',
        path: `${apiAdminOld}/sms/confirm/`,
    },

    sendFile: {
        method: 'POST',
        path: (id, lessonId, blockId) =>
            `${apiUpload}/admin/courses/${id}/lessons/${lessonId}/blocks/${blockId}/upload-file/`,
    },
    getTasks: {
        method: 'GET',
        path: (courseId) => `${apiAdmin}/v1/${courseId ? `courses/${courseId}/` : ``}task-results/`,
    },
    getTasksEvents: {
        method: 'GET',
        path: (courseId) => `${apiAdmin}/v1/events/${courseId}/task-results/`,
    },
    getTask: {
        method: 'GET',
        path: (taskId, userType) => `${apiPrefix}/${userType}/v1/task-results/${taskId}/`,
    },
    getTickets: {
        method: 'GET',
        path: (taskId, userType) => `${apiPrefix}/${userType}/v1/task-results/${taskId}/?include=tickets`,
    },

    startTask: {
        method: 'POST',
        path: (userType) => `${apiPrefix}/${userType}/v1/task-results/`,
    },
    getMessagesList: {
        method: 'GET',
        path: (taskId, userType) => `${apiPrefix}/${userType}/v1/task-results/${taskId}/messages/`,
    },
    sendMessage: {
        method: 'POST',
        path: (userType) => `${apiPrefix}/${userType}/v1/messages/`,
    },
    sendTaskReview: {
        method: 'POST',
        path: (userType, taskId) => `api/student/v1/task-results/${taskId}/check`,
    },
    pollMessages: {
        method: 'POST',
        path: (taskId, userType) => `${apiPrefix}/${userType}/v1/task-results/${taskId}/messages/polling/`,
    },
    declineTask: {
        method: 'POST',
        path: (taskId) => `${apiAdmin}/v1/task-results/${taskId}/decline/`,
    },
    acceptTask: {
        method: 'POST',
        path: (taskId) => `${apiAdmin}/v1/task-results/${taskId}/approve/`,
    },
    reworkTask: {
        method: 'POST',
        path: (taskId) => `${apiAdmin}/v1/task-results/${taskId}/rework/`,
    },
    getPrivacyPolicy: {
        method: 'GET',
        path: (courseId) => `${apiAdminOld}/settings/${courseId}/privacyPolicy/`,
    },
    savePrivacyPolicy: {
        method: 'POST',
        path: (courseId) => `${apiAdminOld}/settings/${courseId}/privacyPolicy/do/`,
    },

    // Академия
    academy: {
        // Вкладка "Курсы"
        getCoursesList: {
            method: 'GET',
            path: (number, size) => `${apiCabinet}/v1/courses/?page[number]=${number}&page[size]=${size}`,
        },

        getCourse: {
            method: 'GET',
            path: (id) => `${apiCabinet}/v1/courses/${id}/`,
        },
        //Получить список уроков в курсе с пагинацией
        getPaginationLessonsList: {
            method: 'GET',
            path: (id, page, size, include) => {
                let params

                params = []
                if (page) {
                    params.push(['page[number]', page].join('='))
                }
                if (size) {
                    params.push(['page[size]', size].join('='))
                }
                if (include) {
                    params.push(['include', include.join(',')].join('='))
                }

                return `${apiCabinet}/v1/courses/${id}/lessons${params.length ? `?${params.join('&')}` : ''}`
            },
        },
        getLessonsList: {
            method: 'GET',
            path: (id) => `${apiCabinet}/v1/courses/${id}/lessons/`,
        },

        setCourseBookmark: {
            method: 'GET',
            path: (id) => `${apiCabinet}/v1/courses/add_favorite_course/${id}`,
        },

        unsetCourseBookmark: {
            method: 'GET',
            path: (id) => `${apiCabinet}/v1/courses/delete_favorite_course/${id}`,
        },

        // Вкладка "Задания"
        getHomework: {
            path: `${apiCabinet}/v1/task-results/`,
            method: 'GET',
        },

        // Вкладка "Финансы/Подписки"
        getSubscriptions: {
            method: 'GET',
        },

        // Вкладка "Финансы/История платежей"
        getPayments: {
            method: 'GET',
            path: (startDate, endDate) =>
                `${apiCabinet}/v1/payments/?filter[startDate]=${startDate}&filter[endDate]=${endDate}`,
        },

        // Вкладка "Статистика"
        getStatistics: {
            method: 'GET',
        },

        // Вкладка "Сертификаты"
        getCertificates: {
            method: 'GET',
        },

        // Страница курса
        getCourseData: {
            method: 'GET',
        },

        // Страница Урока
        getLessonData: {
            method: 'GET',
            path: (id, include) => {
                let params
                params = []
                if (include) {
                    params.push(['include', include.join(',')].join('='))
                }
                return `${apiCabinet}/v1/lessons/${id}${params.length ? `?${params.join('&')}` : ''}`
            },
        },

        buyCourse: {},
        continueCourse: {},
        getCourseStatisticCharts: {
            method: 'GET',
            path: (type, eventId, query) =>  {
                let params = [], result
                Object.entries(query).map(([key, value]) => params.push([key, value].join('=')));
                result = params.length ? [`?${params.join('&')}`].join('/') : '';
                return `${apiAdmin}/v1/courses/statistics/charts/${type}/${eventId}/${result}`;
            }
        }
    },
};
