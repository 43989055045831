import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Burger from "../../../../../assets/images/icons/16/burger.svg";
import {useTranslation} from "react-i18next";
import {postData} from "@/utils/sendData";
import {API} from "@/api/API";

const BottomBarUser = (props) => {
    const {t} = useTranslation('common')
    const dispatch = useDispatch();
    const store = useSelector(state => state);

    useEffect(() => {

    }, [])

    const handAllTicket = () => {
        dispatch({
            type: "SET_FORM", payload: {
                ...store.form,
                data: {
                    ...store.form.data,
                    tickets: [],
                }
            }
        })
        dispatch({
            type: "SET_FORM_TICKET_MEMBER",
            payload: {...store.ticketMember, user: null}
        })
        dispatch({type: "SET_FORM_PREV_TEMPLATE", payload: store.formTemplate})
        dispatch({type: "SET_FORM_TEMPLATE", payload: 'ticket_member'})
    }

    return (
        <div className="legendBar">
            {store.formBtnOtherTickets && <button style={{minWidth: 'fit-content'}}
                                                  className={'a-btn a-btn_size_small a-btn_type_secondary-default'}
                                                  onClick={handAllTicket}
                                                  type={'button'}
            ><Burger style={{marginRight: "8px"}}/>{t('other_tickets')}
            </button>}
            <div className="item">
                <div className="square square__white"></div>
                <span>{t('occupied')}</span>
            </div>
            <div className="item">
                <div className="square square__gray"></div>
                <span>{t('not_for_sale')}</span>
            </div>
            {props.groups.map((item, i) => {
                return <div key={i} className="item">
                    <div className="square" style={{background: item.color}}></div>
                    {item.pivot && <span>{t('from_cost')} {item.pivot['min_price']}₽</span>}
                </div>
            })}
        </div>
    );
};

export default BottomBarUser;
